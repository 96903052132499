<template>
    <div>
        <SubHeader :title="'WITHDRAWAL'" :link="'/mypage'"/>
        <div class="content_wrap">
            <div class="border-bottom border-yellow-1">
                <p class="text-white mb-2">Enter Withdrawing Amount</p>
                <div class="rounded-1 h-px-48 bg-white flex-between-center">
                    <input type="number" class="form-control border-0 h-100" :placeholder="`My Balance: ${balance}`" v-model="w_balance" @focusout="BalanceChange()" >
                    <div class="px-2">{{coin}}</div>
                </div>
                <ul class="text-burgundy-4 py-3">
                    <li class="fs-px-12 d-flex mb-2">
                       <span class="me-2">·</span>
                       <span>Minimum Withdrawal Amount is ( {{min}} {{ coin }} )</span>
                    </li>
                    <li class="fs-px-12 d-flex">
                       <span class="me-2">·</span>
                       <span>Internal transaction(transaction towards OCB wallet) is not supported yet.</span>
                    </li>
                </ul>
            </div>

            <div class="border-bottom border-yellow-1 py-3">
                <p class="text-white mb-2">Address</p>
                <div class="rounded-1 h-px-48 bg-white flex-between-center">
                    <input type="text" class="form-control border-0 h-100" placeholder="Enter Address." v-model="address">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/scan_qr.svg" alt="scan qrcode" width="28" @click="ShowChange()" v-if="show ==false"/>
                        <img src="@/assets/img/layout_icon/x_circle.svg" alt="remove form" width="28" v-if="show ==true"  @click="ShowChange()"/>
                    </div>
                </div>
                <div class="col-12" v-if="show">
                    <qrcode-stream @decode="onDecode"></qrcode-stream>
                </div>
            </div>

            <div class="py-3 pb-5">
                <ul class="fs-px-12">
                    <li class="flex-between-center mb-2">
                        <span class="text-burgundy-4">Network Fee {{fee}} {{coin}}</span>
                        <span class="text-white">= {{fee}} {{coin}}</span>
                    </li>
                    <li class="flex-between-center">
                        <span class="text-burgundy-4">Receive Amount</span>
                        <span class="text-white">= {{send_balance}} {{coin}}</span>
                    </li>
                </ul>
            </div>

            <div class="flex-between-center">
                <button class="py-3 border-0 rounded-1 bg-yellow-1 btn w-100 flex-center-center fw-bold" @click="SendCheck()">
                    Withdraw
                </button>
            </div>
        </div>
        <Footer></Footer>

        <!-- WITHDRAWAL SUMMARY -->
        <b-modal v-model="pp" centered hideHeader hide-footer body-class="pb-0" footer-class="pt-0">
            <div class="content bg-body rounded-1 pb-3">
                <div class="body overflow-auto">
                    <div class="text-center">
                        <h6 class="fw-bold fs-px-17 py-3 pb-4">WITHDRAWAL SUMMARY</h6>
                        
                        <ul class="fs-px-12">
                            <li class="flex-between-center mb-3">
                                <span>Withdrawing Amount</span>
                                <span class="fw-bold">{{w_balance}} {{coin}}</span>
                            </li>
                            <li class="flex-between-center mb-3">
                                <span>Network Fee</span>
                                <span class="fw-bold">{{fee}} {{coin}}</span>
                            </li>
                            <li class="flex-between-center mb-3">
                                <span>Receiving Amount</span>
                                <span class="fw-bold">{{send_balance}} {{coin}}</span>
                            </li>
                            <li class="flex-between-center">
                                <span>Address</span>
                                <span class="fw-bold">{{address}}</span>
                            </li>
                        </ul>

                        <p class="py-4 fs-px-12 text-danger">Withdrawal request can not be recalled.<br>
                            Are you sure you want to withdraw?</p>

                        <div class="flex-between-center mt-2">
                            <button class="h-px-48 border-0 rounded-1 bg-white w-100 flex-center-center btn me-2" @click="Cancel()">
                                <small class="fs-px-12 lh-1">CANCEL</small>
                            </button>
                            <button class="h-px-48 border-0 rounded-1 bg-yellow-1 w-100 flex-center-center btn" @click="Confirm()">
                                <small class="fs-px-12 lh-1" >CONFIRM</small>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <Loading v-if="loading"/>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
import { BModal } from 'bootstrap-vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
import Loading from '@/components/common/Loading.vue'

export default {
    components:{
        SubHeader, Footer, BModal,Loading
    },
    data(){
        return{
            coin: this.$route.params.symbol,
            address: '',
            min : '',
            fee : '',
            balance : '',
            w_balance : '',
            send_balance : '',
            info_open: false,
            pp: false,
            loading : false,
            show : false,
        }
    },
    mounted(){
        this.GetWalletWithdrawInfo()
    },
    methods:{
        ShowChange(){
            this.show = !this.show;

        },
        BalanceChange(){
            const w_balance = this.w_balance;
            const min = this.min;
            const fee = this.fee;
            const min_check = exactMath.sub(min,w_balance);

            if(min_check >0){
                this.send_balance = 0;
                this.w_balance = '';
                this.$alert("Please check the minimum withdrawal amount.")
                return false;
            }

            const balance = this.balance;

            const balance_check = exactMath.sub(balance,w_balance);

            if(balance_check < 0 ){
                this.send_balance = 0;
                this.w_balance = '';
                this.$alert("Please check the amount of money you have in your wallet.");

                return false;
            }



            this.send_balance = exactMath.sub(w_balance,fee);
        },
        onDecode(value){
            this.address = value;
            this.show =false;
        },
        SendCheck(){
            const w_balance = this.w_balance;
            const min = this.min;
            const fee = this.fee;
            const min_check = exactMath.sub(min,w_balance);

            if(min_check >0){
                this.send_balance = 0;
                this.w_balance = '';
                this.$alert("Please check the minimum withdrawal amount.")
                return false;
            }else{
                this.send_balance = exactMath.sub(w_balance,fee);
                this.pp=true;
            }
        },
        GetWalletWithdrawInfo(){
            const coin = this.coin;
            const body = {coin};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/transaction/GetWalletWithdrawInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.fee = body.info.fee;
                            this.balance = body.info.balance;
                            this.min = body.info.min;
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        Cancel(){
            this.$alert("Canceled").then(()=>{
                this.$router.push('/mypage')
            })
        },
        Confirm(){
            const coin = this.coin;
            const w_balance = this.w_balance;
            const address = this.address;
            const body = {coin,w_balance,address};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.pp = false;
            this.loading = true;
            this.$http.post('/member/transaction/AddWithdraw',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$alert("Request Complete!").then(()=>{
                                this.pp = false;
                                this.$router.push("/transaction/history")
                            })
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        }
    }

}
</script>